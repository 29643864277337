import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import VueHtml2pdf from 'vue-html2pdf';
export default {
  components: {
    // Dropdown1
    VueHtml2pdf
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "currentYear"
    ]),
    getLogo(){
      return process.env.BASE_URL+'media/logos/logo.jpg';
    }
  },

  data() {
    return {
      title: "Status Tracking",
      
      // :core
      dataUser: {},
      searchMain: {},
      tContentDetail: {},
      tContents: [],
      tContentsTotal: 1,
      tContentsHeader: [
            
      ],

      tContentsOptions: {},
      tContentsLoading: false,
      tContentsDisabled: false,
      tContentsPaginations: [5,10,15,25,50,100],
      tContentsSearch: {},
      param_id: null,
    };
  },

  mounted() {

    this.dataUser = this.currentUser;
    this.searchMain.tahun = this.currentYear;
    this.searchMain.admin_id = Number(this.currentUser.id);
    this.searchMain.gudang_id = Number(this.currentUser.gudang_id);
    this.role_kode = this.currentUser.role_kode;
    this.user_id = Number(this.currentUser.id);
    this.user_id_induk = Number(this.currentUser.atasan_id);
    

    this.param_id = this.$route.params.id;

    let packobject = this.$route.query;
    this.flag = packobject.flag;
    
    if(this.param_id){
      this.loadMainContent();
    }
  },

  methods: {
    loadMainContent(){
      this.tContentsLoading = true;
      var noSj = this.param_id;
      ApiService.get("https://app.namlogistik.id/ekspedisi-api/api/surat_jalan/statusTracking/"+noSj).then(({ data }) => {
        this.tContentsLoading = false;
        this.tContents = data;
      }).catch(({ response }) => {
        this.tContentsLoading = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      })
    },
  },
  
};